import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import Heading from "../components/basic-components/heading/heading";
import * as styles from "./404.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../components/container/container";
import classNames from "classnames";

const NotFoundPage = () => (
  <Layout hideWhereToBuy={true} hideTips={true}>
    <title>Sayfa Bulunamadı</title>
    <Container>
      <div className={classNames("flexbox", styles.holder)}>
        <StaticImage
          className={styles.image}
          src="../images/404-image.webp"
          alt="Sayfa bulunamadı görseli"
          width={250}
          height={215}
        />
        <Heading>Aradığınız sayfaya ulaşamıyoruz.</Heading>
        <p>
          Sayfa silinmiş ya da yayından kaldırılmış olabilir. Dilerseniz{" "}
          <Link className="blue underline bold" to={"/"}>buraya</Link> tıklayarak anasayfaya gidebilirsiniz.
        </p>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
